import { graphql, Link } from "gatsby"
import { withPreview } from "gatsby-source-prismic"
import React from "react"
import FeaturedPost from "../components/featuredPost"
import { LocaleContext } from "../components/layout"
import SEO from "../components/seo"

const CategoryTemplate = ({ data, location, pageContext }) => {
  const category = data.prismicCategory.data
  const posts = data.categoryPosts.nodes
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const { numPages, currentPage } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const locationArray = location.pathname.split("/")
  locationArray.shift()
  locationArray.pop()
  const firstPage = locationArray.join("/")
  const prevPage =
    currentPage - 1 === 1
      ? `/${firstPage}`
      : `../${(currentPage - 1).toString()}`
  const nextPage = isFirst
    ? (currentPage + 1).toString()
    : `../${(currentPage + 1).toString()}`
  return (
    <>
      <SEO
        title={category.name}
        description={data.site.siteMetadata.description}
      />
      <div className="container">
        <div className="row">
          <article
            className="category-page col"
            itemScope
            itemType="http://schema.org/Article"
          >
            <header>
              <h1 itemProp="headline">{category.name}</h1>
            </header>
            <section itemProp="articleBody">
              <div className="row">
                {posts?.map(post => {
                  return (
                    <div className="col-sm-12 col-md-4" key={post.uid}>
                      <FeaturedPost post={post} />
                    </div>
                  )
                })}
              </div>
            </section>
            <section itemProp="paginator">
              <div className="row">
                {!isFirst && (
                  <div className="col">
                    <Link
                      to={prevPage}
                      rel="prev"
                      itemProp="url"
                      title={i18n.previousPage}
                    >
                      ← {i18n.previousPage}
                    </Link>
                  </div>
                )}
                {!isLast && (
                  <div className="col text-right">
                    <Link
                      to={nextPage}
                      rel="next"
                      itemProp="url"
                      title={i18n.nextPage}
                    >
                      {i18n.nextPage} →
                    </Link>
                  </div>
                )}
              </div>
            </section>
          </article>
        </div>
      </div>
    </>
  )
}

export default withPreview(CategoryTemplate)

export const pageQuery = graphql`
  query CategoryBySlug(
    $uid: String!
    $locale: String!
    $skip: Int!
    $limit: Int!
  ) {
    site {
      siteMetadata {
        title
        description
      }
    }
    prismicCategory(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      data {
        name
      }
    }
    categoryPosts: allPrismicPost(
      filter: {
        data: {
          categories: {
            elemMatch: {
              category: { uid: { eq: $uid }, lang: { eq: $locale } }
            }
          }
        }
      }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      nodes {
        uid
        url
        data {
          title {
            text
          }
          date(formatString: "DD.MM.YYYY")
          featured_image {
            fluid(maxHeight: 800) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
  }
`
