/**
 * Featured posts section component
 */

import { Link } from "gatsby"
import React from "react"
import Img from "gatsby-image"

const FeaturedPost = ({ post }) => {
  return (
    <article className="featured-post">
      <header>
        <Link to={post.url} itemProp="url">
          <Img
            fluid={post.data.featured_image.fluid}
            className="featured-post-image"
          ></Img>
        </Link>
        <h3>
          <Link to={post.url} itemProp="url">
            {post.data.title.text}
          </Link>
        </h3>
      </header>
    </article>
  )
}

export default FeaturedPost
